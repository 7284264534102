import React from 'react';
import linuxcorpLogo from 'assets/img/Creatimus/Linuxcorp.png';
import classNames from 'classnames';

interface LogoProps {
  width?: number;
  className?: string;
}

const Logo2 = ({ width = 200, className }: LogoProps) => {
  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <img src={linuxcorpLogo} alt="Linuxcorp" width={width} />
    </div>
  );
};

export default Logo2;