import TestimonialCarousel from 'components/sliders/TestimonialCarousel';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import bg12 from 'assets/img/bg/bg-12.png';
import bg13 from 'assets/img/bg/13.png';
import api from 'assets/img/Creatimus/api.png';

const Testimonial = () => {
  return (
    <div className="bg-body position-relative py-4">
      <div
        className="position-absolute h-100 w-100 top-0 bg-body-emphasis"
       
      />
      <div
        className="bg-holder z-2 d-none d-md-block"
        style={{
          backgroundImage: `url(${bg13})`,
          backgroundSize: 'auto',
          backgroundPosition: 'right'
        }}
      />
      <div
        className="bg-holder z-2 d-none d-md-block d-lg-none d-xl-block"
        style={{
          backgroundImage: `url(${bg12})`,
          backgroundSize: 'auto',
          backgroundPosition: 'left'
        }}
      />

      <section id="anubis" className="overflow-hidden position-static">
        <div className="container-small px-lg-7 px-xxl-3">
          <Row>
            <Col lg={12} className="text-center text-lg-start z-2">
              <div>
              <h1 className="text-success mb-2 me-3">Anubis AI Platform</h1>
                <h2 className="mb-5 text-body-emphasis">
                Where AI meets secure, seamless task-driven automation.
                </h2>
               
                  Anubis is an advanced AI platform that seamlessly combines 
                  specialized agents with the power of NeuroSkills. It processes 
                  real-time data, intelligently querying and analyzing it to deliver
                  highly accurate, context-aware responses. With robust integration
                  capabilities, Anubis connects to third-party applications like 
                  WhatsApp and Google Servivces for example, enabling dynamic interactions through
                  text, voice, and video avatars.
                  <br /><br />
                  <h3 className="text-success me-3 mt-4 mb-6">Modular, Scalable & Limitless API Control</h3>
                

                  <Row className="justify-content-center text-center">
  <Col md={10} className="d-flex justify-content-center mb-3">
    <img 
      src={api}
      alt="Description"
      style={{ maxWidth: "100%", height: "auto", display: "block" }} 
    />
  </Col>
</Row>
                  <br></br>
                  Anubis goes beyond simple communication—it’s a versatile, secure
                  platform that can execute tasks in both local and remote environments,
                  ensuring privacy and security at all times. By connecting to external
                  services through APIs, Anubis not only enhances its own functionality
                  but also serves as an API provider, offering a flexible, scalable
                  solution for a wide range of applications. Whether it’s performing
                  client-server commands or driving complex processes, Anubis is
                  engineered to adapt and deliver.
       
              </div>
            </Col>
           {/* <Col lg={6} className="z-2">
              <TestimonialCarousel />
            </Col>*/}
          </Row>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
