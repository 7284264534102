import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UilEnvelope, UilMapMarker, UilPhone } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const HelpCenter = () => {
  return (
    <>
      <h1 className="text-success">Contact Us</h1>
      <h3 className="mb-3 mt-2">Let's Stay Connected</h3>
      <p className="mb-2">Stay connected with us. Schedule your demo today!</p>

      {/* Social Media Icons */}
      <a href="https://www.linkedin.com/company/creatimus/" className="me-2">
        <FontAwesomeIcon icon={faLinkedin} className="text-success fs-6" />
      </a>
      <a href="https://x.com/creatimussocial" className="me-2">
        <FontAwesomeIcon icon={faXTwitter} className="text-success fs-6" />
      </a>
      <a href="https://www.facebook.com/people/Creatimus/61568375258965/" className="me-2">
        <FontAwesomeIcon icon={faFacebookSquare} className="text-success fs-6" />
      </a>
      <a href="https://www.instagram.com/creatimus.social/" className="me-2">
        <FontAwesomeIcon icon={faInstagram} className="text-success fs-6" />
      </a>
      <a href="https://www.tiktok.com/@creatimus.com" className="me-2">
        <FontAwesomeIcon icon={faTiktok} className="text-success fs-6" />
      </a>

      {/* Contact Information */}
      <div className="mt-3 d-flex flex-column align-items-center align-items-md-start gap-4">
        <div className="d-flex flex-column flex-md-row gap-2 align-items-center">
          <div className="icon-wrapper shadow-info">
            <UilPhone size={40} className="text-success fs-4 z-1 ms-2" data-bs-theme="light" />
          </div>
          <div className="text-success flex-1 ms-3">
            <Link to="tel:+18329660445" className="link-900">
              +1 (832) 966-0445
            </Link>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row gap-2 align-items-center">
          <div className="icon-wrapper shadow-info">
            <UilEnvelope size={40} className="text-success z-1 ms-2" data-bs-theme="light" />
          </div>
          <div className="flex-1 ms-3">
            <Link to="mailto:support@creatimus.com" className="fw-semibold text-body">
              support@creatimus.com
            </Link>
          </div>
        </div>
        <div className="mb-6 d-flex flex-column flex-md-row gap-2 align-items-center">
          <div className="icon-wrapper shadow-info">
            <UilMapMarker size={40} className="text-success z-1 ms-2" data-bs-theme="light" />
          </div>
          <div className="flex-1 ms-3">
            <div className="fw-semibold text-body">
              <b>Creatimus LLC</b> <br />
              United States - Houston, TX <br /><br />
              <b>Linuxcorp Networks</b><br />
              Santiago, Chile.<br />
              Houston, Texas
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenter;