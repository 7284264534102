import illustration22 from 'assets/img/spot-illustrations/22_2.png';
import illustration22dark from 'assets/img/spot-illustrations/dark_22.png';
import illustration23 from 'assets/img/spot-illustrations/33-s.webp';
import illustration23dark from 'assets/img/spot-illustrations/33-s.webp';
import illustration24 from 'assets/img/spot-illustrations/dark403-illustration.png';
import illustration24dark from 'assets/img/spot-illustrations/dark403-illustration.png';
import blog1 from 'assets/img/blog/blog-1.png';
import blog2 from 'assets/img/blog/blog-2.png';
import blog3 from 'assets/img/blog/blog-3.png';
import blog4 from 'assets/img/blog/blog-4.png';

export type Feature = {
  lightImage: string;
  darkImage: string;
  label: string;
  title: string;
  details: string;
  link: string;
};

export type Blog = {
  id: number;
  image: string;
  views: number;
  likes: number;
  comments: number;
  category: string;
  title: string;
};

export const defaultFeatures: Feature[] = [
  {
    lightImage: illustration22,
    darkImage: illustration22dark,
    label: 'Modular Integration',
    title: 'Adaptable API',
    details:
      'Anubis AI is built with an API-first approach, ensuring seamless integration into your existing systems. This modularity allows for effortless adaptation and connectivity.',
    link: '#!'
  },
  {
    lightImage: illustration23,
    darkImage: illustration23dark,
    label: 'Scalability',
    title: 'See Your Revenue Grow',
    details:
      'Grow with our Intelligent Network Solutions. We provide the tools and support for your network to expand alongside your business, driving increased revenue and efficiency.',
    link: '#!'
  },
  {
    lightImage: illustration24,
    darkImage: illustration24dark,
    label: 'Security',
    title: 'Proactive Threat Mitigation',
    details:
      'Our Sentinel AI product provides round-the-clock, proactive threat mitigation, ensuring your network defenses are always one step ahead.',
    link: '#!'
  }
];

export const blogs: Blog[] = [
  {
    id: 1,
    image: blog1,
    views: 2563,
    likes: 125,
    comments: 125,
    category: 'SEO',
    title: 'Top 10 ways to Ace SEO for your business'
  },
  {
    id: 2,
    image: blog2,
    views: 1256,
    likes: 325,
    comments: 32,
    category: 'Marketing',
    title: 'Top 12 Marketing strategies you can take'
  },
  {
    id: 3,
    image: blog3,
    views: 142,
    likes: 123,
    comments: 22,
    category: 'Marketing',
    title: 'The top 7 methods to improve as a marketer'
  },
  {
    id: 4,
    image: blog4,
    views: 2563,
    likes: 325,
    comments: 112,
    category: 'Tech',
    title: 'Best places for a tech job in U.S'
  }
];
