import {
  faFacebookSquare,
  faXTwitter,
  faLinkedin,
  faInstagram,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from 'components/common/Logo';
import Logo2 from 'components/common/Logo2';
import { PropsWithChildren } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LinkItem = ({ children, to }: PropsWithChildren<{ to: string }>) => {
  return (
    <Link to={to} className="text-body-tertiary fw-semibold fs-9 mb-1">
      {children}
    </Link>
  );
};

const EcommerceFooter = () => {
  return (
    <section className="bg-body-highlight dark__bg-gray-1100 py-9">
      <div className="container-small">
        <Row className="justify-content-between gy-4">
          <Col xs={12} lg={4}>
            <p className="text-body-tertiary mb-4 fw-semibold lh-sm fs-9 position-relative">
              {/* Logo2 as watermark */}
              <Logo2 className="mb-1 opacity-25" />
              {/* Logo as watermark */}
              <Logo className="mb-2 opacity-25 mb-4" />
              security, privacy, and responsible AI development are at the core of everything we do. We uphold the highest standards of data protection, compliance, and ethical AI practices to ensure innovation without compromise. Your trust fuels our commitment to a safer, smarter future.
            </p>
          </Col>
          <Col xs={6} md="auto">
            <h5 className="fw-bolder mb-3">About us</h5>
            <Stack>
              <LinkItem to="#!">Careers</LinkItem>
              <LinkItem to="#!">Affiliate Program</LinkItem>
              <LinkItem to="#!">Privacy Policy</LinkItem>
              <LinkItem to="#!">Terms & Conditions</LinkItem>
            </Stack>
          </Col>
          <Col xs={6} md="auto">
            <h5 className="fw-bolder mb-3">Stay Connected</h5>
            <Stack>
              <LinkItem to="https://www.linkedin.com/company/creatimus/">
                <FontAwesomeIcon icon={faLinkedin} className="me-2 text-success" />
                LinkedIn
              </LinkItem>
              <LinkItem to="https://x.com/creatimussocial">
                <FontAwesomeIcon icon={faXTwitter} className="me-2 text-success" />
                X (Twitter)
              </LinkItem>
              <LinkItem to="https://www.facebook.com/people/Creatimus/61568375258965/">
                <FontAwesomeIcon icon={faFacebookSquare} className="me-2 text-success" />
                Facebook
              </LinkItem>
              <LinkItem to="https://www.instagram.com/creatimus.social/">
                <FontAwesomeIcon icon={faInstagram} className="me-2 text-success" />
                Instagram
              </LinkItem>
              <LinkItem to="https://www.tiktok.com/@creatimus.com">
                <FontAwesomeIcon icon={faTiktok} className="me-2 text-success" />
                TikTok
              </LinkItem>
            </Stack>
          </Col>
          <Col xs={6} md="auto">
            <h5 className="fw-bolder mb-3">Customer Service</h5>
            <Stack>
              <LinkItem to="#!">Help Desk</LinkItem>
              <LinkItem to="#!">Support, 24/7</LinkItem>
              <LinkItem to="#!">FAQ</LinkItem>
            </Stack>
          </Col>
          <Col xs={6} md="auto">
            <h5 className="fw-bolder mb-3">Services</h5>
            <Stack>
              <LinkItem to="#!">Intelligent Networks</LinkItem>
              <LinkItem to="#!">AI Integration</LinkItem>
              <LinkItem to="#!">Cyber Security</LinkItem>
              <LinkItem to="#!">Cloud Infrastructure</LinkItem>
              <LinkItem to="#!">IT Automation</LinkItem>
            </Stack>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default EcommerceFooter;