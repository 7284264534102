import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import hazbot from 'assets/img/Creatimus/hazbot.png';
import firebot from 'assets/img/Creatimus/firebot.png';
import medbot from 'assets/img/Creatimus/medbot.png';
import xgis from 'assets/img/Creatimus/xgis.png';
import sentinel from 'assets/img/Creatimus/sentinel.png';
import AIDetect from 'assets/img/Creatimus/AIDetect.png';
import whatsapp from 'assets/img/Creatimus/whatsapp.png';
import google from 'assets/img/brands/google.png';

const Brand = ({ image, className }: { image: string; className?: string }) => {
  return (
    <div
      className={classNames(
        className,
        'p-2 p-lg-5 d-flex flex-center h-100 border-dashed border-translucent'
      )}
    >
      <img src={image} alt="" className="w-100" />
    </div>
  );
};

const Brands = ({ className }: { className?: string }) => {
  return (
    <section className={className}>
      <div className="container-small px-lg-7 px-xxl-3">
        <Row className="g-0">
          <Col xs={6} md={3}>
            <Brand
              image={hazbot}
              className="border-bottom border-end border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={firebot}
              className="border-bottom border-end-md border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={medbot}
              className="border-bottom border-end border-end-md border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={xgis}
              className="border-bottom border-end-lg-0 border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={sentinel}
              className="border-end border-bottom border-bottom-md-0 border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={AIDetect}
              className="border-end-md border-bottom border-bottom-md-0 border-translucent"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={whatsapp} className="border-end border-translucent" />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={google}
              className="border-end-lg-0 border-translucent"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Brands;