import { Stack } from 'react-bootstrap';
import demoVideo from 'assets/video/PromoVideo.mp4'; // Ensure this is the correct path
import { useEffect, useRef } from 'react';

const Starter = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // Ensure the video is muted initially
      video.muted = true;
      video.play().catch((error) => {
        console.error("Autoplay failed: ", error);
      });
    }
  }, []);

  return (
    <Stack className="flex-center content-min-h">
     {/* <video
        ref={videoRef}
        className="bg-video"
        autoPlay
        playsInline
        muted={true}  // Keep muted in JSX
        src={demoVideo}
        loop
      /> */}
      {/* Add a button or control to unmute the video if desired */}
    </Stack>
  );
};

export default Starter;