import React, { LegacyRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import bgRight21 from 'assets/img/bg/bg-right-21.png';
import bgLeft21 from 'assets/img/bg/bg-left-21.png';
import anubis from 'assets/img/Creatimus/anubis.png';
import connect from 'assets/img/Creatimus/connect.png';
import CountUp from 'react-countup';
import '../../../../../assets/scss/user.scss';

const FunFacts = () => {
  return (
    <div className="pb-0">
      <div className="position-relative py-2">
      
        <div
          className="bg-holder z-2 opacity-25"
          style={{
            backgroundImage: `url(${bgRight21})`,
            backgroundSize: 'auto',
            backgroundPosition: 'right'
          }}
        />
        <div
          className="bg-holder z-2 mt-9 opacity-25"
          style={{
            backgroundImage: `url(${bgLeft21})`,
            backgroundSize: 'auto',
            backgroundPosition: 'left'
          }}
        />

        <section id="solutions" className="overflow-hidden z-2">
          <div
            className="container-small px-lg-7 px-xxl-3"
            data-bs-theme="light"
          >
            <div className="position-relative">
              <Row className="row mb-10">
                <div className="col-xl-6 text-center text-md-start">
                  <h2 className="text-white mb-2">
                    Elevate Your Business with 
                  </h2>
                  <h1 className="fs-md-3 fs-xl-2 fw-black text-success text-uppercase mb-4 mb-md-0">
                    Intelligent AI
                  </h1>
                </div>
                <div className="col-xl-6 text-center text-md-start">
                  <p className="text-white">
                    As AI continues to evolve, companies that embrace 
                    its capabilities gain a competitive edge, streamlining 
                    workflows, improving accuracy, and unlocking new levels 
                    of innovation. Whether through automation, intelligent 
                    analytics, or seamless integrations, AI is reshaping 
                    the way businesses operate.
                  </p>
                </div>
              </Row>

              <Row className="d-flex justify-content-center text-center">
                <Col xs={12} md={4} className="mb-4 mb-md-0">
                  <CountUp end={72} duration={5} suffix="%" enableScrollSpy>
                    {({ countUpRef }) => (
                      <div>
                        <h1 className="display-1 text-white fw-bolder" ref={countUpRef as LegacyRef<HTMLHeadingElement>} />
                      </div>
                    )}
                  </CountUp>
                  <p className="text-white"><b>72% of organizations have integrated AI </b>into their operations.</p>
                </Col>

                <Col xs={12} md={4} className="mb-4 mb-md-0">
                  <CountUp end={65} duration={5} suffix="%" enableScrollSpy>
                    {({ countUpRef }) => (
                      <div>
                        <h1 className="display-1 text-white fw-bolder" ref={countUpRef as LegacyRef<HTMLHeadingElement>} />
                      </div>
                    )}
                  </CountUp>
                  <p className="text-white"><b>65% Leverage generative AI </b>for innovation and automation.</p>
                </Col>

                <Col xs={12} md={4} className="mb-4 mb-md-0">
                  <CountUp end={92} duration={5} suffix="%" enableScrollSpy>
                    {({ countUpRef }) => (
                      <div>
                        <h1 className="display-1 text-white fw-bolder" ref={countUpRef as LegacyRef<HTMLHeadingElement>} />
                      </div>
                    )}
                  </CountUp>
                  <p className="text-white mb-8"><b>92% of businesses report</b> measurable benefits, including <b>cost reduction and enhanced customer service.</b></p>
                </Col>
              </Row>

              <Row className="d-flex justify-content-center align-items-center">
                <Col xl={6} className="text-center">
                  <img className="img-fluid" src={anubis} alt="Anubis Logo" />
                </Col>
              </Row>

            {/* New Section: Anubis Capabilities and Features */}
<Row className="mt-6">
  <div className="col-12 text-center">
    <h2 className="text-success-light mb-4">Redefining Possibilities with Advanced Capabilities & Features</h2>
    <p className="mb-4">
      Anubis is designed to empower businesses with intelligent automation and real-time decision-making. Below is an overview of Anubis' key capabilities and the areas where it can be implemented.
    </p>
  </div>
</Row>
<Row className="justify-content-center text-center">
  <Col md={10} className="d-flex justify-content-center">
    <img 
      src={connect}
      alt="Description"
      style={{ maxWidth: "100%", height: "auto", display: "block" }} 
    />
  </Col>
</Row>
             
              <Row className="mt-5">
  <Col xs={12} md={6} className="mb-4 mb-md-0">
    <h2 className="text-success-light mb-3">Key Capabilities</h2>
    <ul className="list-unstyled">
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Advanced Data Processing:</strong> Real-time analysis and decision-making using cutting-edge algorithms.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Task Automation:</strong> Automates complex tasks across various business functions.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>AI-Powered Communication:</strong> Multimodal interaction support for chat, voice, and soon video-based communication.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Security and Privacy:</strong> High-end security protocols, including end-to-end encryption and compliance with privacy standards.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>API Integration:</strong> Easy integration with third-party applications via flexible APIs.
      </li>
    </ul>
  </Col>

  <Col xs={12} md={6} className="mb-4 mb-md-0">
    <h2 className="text-success-light mb-3">Areas of Implementation</h2>
    <ul className="list-unstyled">
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Customer Service:</strong> AI-driven chatbots, ticketing systems, and customer interaction automation.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Healthcare:</strong> Diagnostics, risk assessment, and patient management automation.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Finance:</strong> Fraud detection, risk management, and investment analytics.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Manufacturing:</strong> Predictive maintenance and supply chain optimization.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Retail:</strong> Personalized shopping experiences and inventory management.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Logistics:</strong> Route optimization and fleet management.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Education:</strong> Personalized learning and administrative task automation.
      </li>
      <li className="mb-2">
        <span className="text-success-light">• </span>
        <strong>Government:</strong> Public service automation.
      </li>
      <li className="mb-3">
        <span className="text-success-light">• </span>
        <strong>Security and Emergency:</strong> Disaster recovery, crisis management, and emergency response systems.
      </li>
    </ul>
  </Col>
</Row>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FunFacts;