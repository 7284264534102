import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import boltIlls from 'assets/img/icons/illustrations/1.png';
import pie from 'assets/img/icons/illustrations/pie.png';
import { defaultFeatures } from 'data/landing/default-landing-data';
import FeatureSection from './FeatureSection';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
  return (
    <section className="bg-body pt-15 pb-10" id="features">
      <div className="container-small px-lg-7 px-xxl-3">
        <div className="position-relative z-2">
        <Row className="align-items-center mb-12">
  {/* Left Section */}
  <Col lg={6} className="text-center text-lg-start pe-xxl-3 d-flex flex-column justify-content-center h-100 mb-6">
    <h1 className="text-success me-3">Intelligent Networks</h1>
    <p className="mb-5">
      In today's world, businesses are more reliant than ever on their networks. That's why Creatimus & Linuxcorp offer a suite of AI-powered solutions that can help you optimize your network performance, security, and reliability. Our solutions can help you:
    </p>
    <a href="#contact">
      <Button variant="subtle-success">Find out More</Button>
    </a>
  </Col>

  {/* Right Section */}
  <Col lg={6} className="text-center text-lg-start d-flex flex-column justify-content-center h-100">
    <div className="border-start-lg border-dashed border-translucent ps-4">
      <img className="mt-8 mb-4" src={boltIlls} width={48} height={48} alt="" />
      <h4 className="mb-3 text-body-emphasis lh-base">
        Powered by <span className="text-success-light me-3">ANUBIS AI</span>
      </h4>
      <ul className="fw-semibold lh-sm text-start">
        <li>Proactively identify and resolve network issues</li>
        <li>Automate network management tasks</li>
        <li>Improve network security</li>
        <li>Gain insights into network traffic and usage</li>
        <li>Make better decisions about network investments</li>
      </ul>
    </div>
  </Col>
</Row>

          {defaultFeatures.map((feature, index) => (
            <FeatureSection
              key={feature.label}
              feature={feature}
              orderReverse={index === 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
