import linuxcorp from 'assets/img/Creatimus/Linuxcorp.png';
import creatimus from 'assets/img/Creatimus/LogoDark.png';
import bg13 from 'assets/img/bg/bg-32.png';
import bgRight21 from 'assets/img/bg/bg-right-21.png';
import bgLeft21 from 'assets/img/bg/bg-left-21.png';
import React from "react";
import { Col, Row } from 'react-bootstrap';
const Gallery: React.FC = () => {
  return (
    <div className="position-relative py-7">
  <div 
    className="bg-holder position-absolute top-0 start-0 w-100 h-100 z-0" 
    style={{ transform: 'skew(0,-10deg)', backgroundImage: `url(${bg13})` }} 
  />
  <div 
    className="bg-holder position-absolute top-0 end-0 w-100 h-100 z-0 opacity-25" 
    style={{ backgroundImage: `url(${bgRight21})`, backgroundSize: 'contain', backgroundPosition: 'right' }} 
  />
  <div 
    className="bg-holder position-absolute bottom-0 start-0 w-100 h-100 z-0 opacity-25" 
    style={{ backgroundImage: `url(${bgLeft21})`, backgroundSize: 'contain', backgroundPosition: 'left' }} 
  />
  <section id="networks" className="pt-2 overflow-hidden position-relative z-3">
    <div className="container-small position-relative px-lg-7 px-xxl-3">
      <Row className="mb-6 text-center text-sm-start">
        <Col xs={12} className="mb-4">
          <h2 className="text-success-light fs-5 fs-lg-4 fs-xl-3 fw-black mb-0">
            LINUXCORP & 
          </h2>
          <h2 className="text-success fs-5 fs-lg-4 fs-xl-3 fw-black mb-0">
            CREATIMUS
          </h2>
          <h2 className="fs-5 fs-lg-4 fs-xl-3 fw-black mb-0">
            Strategic Alliance
          </h2>
        </Col>
      </Row>     
      <Row className="justify-content-center text-center">
        <Col md={5} className="d-flex justify-content-center mb-8 mt-0">
          <img 
            src={linuxcorp}
            alt="Linuxcorp Logo"
            className="img-fluid d-block"
          />
        </Col>
      </Row>
      <Row className="mb-6 text-center text-sm-start">
  {/* Two columns on top for large screens, stacked for small screens */}
  <Col lg={6} xs={12} className="mb-4">
    <h2 className="fw-bold text-success">Linuxcorp Networks</h2>
    <p>
      Linuxcorp Networks is a leading technology company specializing in 
      network management, cybersecurity, software development, and 
      infrastructure deployment. With a strong foundation in Linux-based 
      and open-source technologies, Linuxcorp provides secure, scalable, 
      and high-performance solutions for businesses and enterprises. 
      The company excels in cloud computing, AI integration, and IT automation, 
      ensuring that clients stay ahead in the rapidly evolving digital landscape. 
      With a commitment to innovation and security, Linuxcorp Networks serves 
      as the backbone framework for AI-driven platforms and cutting-edge 
      digital solutions.
    </p>
  </Col>
  
  <Col lg={6} xs={12}>
  <div className="border-start-lg border-dashed border-translucent ps-4">
    <h2 className="fw-bold text-success">Creatimus</h2>
    <p>
      Creatimus is a technology and AI innovation company dedicated to 
      designing, developing, and deploying intelligent digital solutions for 
      businesses. Headquartered in Houston, Texas, Creatimus focuses on 
      AI-powered applications, automation, and smart digital experiences. 
      The company thrives on creativity, functionality, and efficiency, ensuring 
      that its products help businesses optimize operations, enhance user 
      engagement, and achieve digital transformation. As the creator of 
      Anubis AI and Hazbot, Creatimus is at the forefront of AI-driven 
      solutions, seamlessly integrating technology to simplify complex processes.
    </p>
    </div>
  </Col>

  {/* Full-width column centered below */}
  <Col lg={8} xs={12} className="mx-auto text-center mt-6">
    <h2 className="fw-bold text-success-light">Strategic Alliance</h2>
    <p>
      Together, Linuxcorp Networks and Creatimus form a powerful strategic 
      alliance, combining AI innovation with robust network security and 
      infrastructure. Linuxcorp provides the technological backbone and 
      cybersecurity expertise, while Creatimus delivers cutting-edge AI 
      solutions and digital platforms. This partnership ensures the seamless 
      deployment of intelligent, secure, and scalable AI-driven solutions, 
      empowering businesses across industries.
    </p>
  </Col>
</Row>
      <Row className="justify-content-center text-center">
        <Col md={5} className="d-flex justify-content-center mb-4">
          <img 
            src={creatimus}
            alt="Creatimus Logo"
            className="img-fluid d-block"
          />
        </Col>
      </Row>
    </div>
  </section>
</div>
  );
};
export default Gallery;
