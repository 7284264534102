import Logo from 'components/common/Logo';
import Logo2 from 'components/common/Logo2';
import '../../../assets/scss/user.scss';
import { Modal, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SearchBox from 'components/common/SearchBox';
import Button from 'components/base/Button';
import ThemeToggler from 'components/common/ThemeToggler';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/team/40x40/avatar3.png';
import { faPaperPlane, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

const NavItem = ({
  label,
  url,
  isLast,
  onClick
}: {
  label: string;
  url: string;
  isLast?: boolean;
  onClick?: () => void;
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const element = document.querySelector(url);
    if (element) {
      // Get the navbar height for offset calculation
      const navbar = document.querySelector('.landing-navbar');
      const navbarHeight = navbar ? navbar.getBoundingClientRect().height : 0;

      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (onClick) onClick();
  };

  return (
    <Nav.Item
      as="li"
      className={classNames({
        'border-bottom border-translucent border-bottom-lg-0': !isLast
      })}
    >
      <Nav.Link onClick={handleClick} className="lh-1 py-0 fs-9 fw-bold py-3">
        {label}
      </Nav.Link>
    </Nav.Item>
  );
};

const DefaultLandingNavbar = ({ className }: { className?: string }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    if (expanded) {
      setExpanded(false); // Close the menu when a nav item is clicked on mobile
    }
  };

  return (
    <>
      <div
        className={classNames(
          className,
          'bg-body-emphasis sticky-top landing-navbar'
        )}
        ref={containerRef}
      >
        <Navbar 
          className="px-3 px-lg-7 px-xxl-3 container-small" 
          expand="lg"
          expanded={expanded}
          onToggle={(expanded) => setExpanded(expanded)}
        >
          <Navbar.Brand
            as={Link}
            to="/"
            className="text-decoration-none flex-1 flex-lg-grow-0"
          >
            <Logo />
          </Navbar.Brand>

          {/* Mobile Toggle */}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{
              border: 'none',
              background: 'transparent',
              padding: '0',
            }}
          >
            <FontAwesomeIcon icon={faEllipsis} size="2xl" color="white" />
          </Navbar.Toggle>

          <Navbar.Collapse>
            <Nav className="mx-auto mb-10 mb-lg-0" as="ul">
              <NavItem label="Home" url="#home" onClick={handleNavItemClick} />
              <NavItem label="Intelligent Networks" url="#features" onClick={handleNavItemClick} />
              <NavItem label="Anubis AI" url="#anubis" onClick={handleNavItemClick} />
              <NavItem label="AI Solutions" url="#solutions" onClick={handleNavItemClick} />
              <NavItem label="Partnership" url="#networks" onClick={handleNavItemClick} />
              <NavItem label="Contact Us" url="#contact" isLast onClick={handleNavItemClick} />
            </Nav>     <Logo2/>
          </Navbar.Collapse>
        </Navbar>
 
      </div>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            size="lg"
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DefaultLandingNavbar;