import { Col, Row } from 'react-bootstrap';
import bg12 from 'assets/img/bg/bg-36.png';
import bg28 from 'assets/img/bg/bg-28.png';
import bg29 from 'assets/img/bg/bg-29.png';
import bg30 from 'assets/img/bg/bg-30.png';
import bg31 from 'assets/img/bg/bg-31.png';
import bg36 from 'assets/img/bg/bg-39.png';
import anubis from 'assets/img/bg/anubis.png';
import anubisnetwork from 'assets/img/bg/anubisnetwork.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const HeroHeader = () => {
  return (
    <section 
      className="pb-1" 
      id="home" 
      style={{
        backgroundImage: `url(${anubisnetwork})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container-small hero-header-container px-lg-7 px-xxl-3">
        <Row className="align-items-center">
          <Col
            xs={12}
            lg="auto"
            className="text-end order-0 order-md-1 order-1"
          >
            <div className="position-relative p-md-7 d-lg-none">
              <div
                className="bg-holder banner-bg"
                style={{ backgroundImage: `url(${bg36})`, backgroundSize: 'contain' }}
              />
              <div className="position-relative">
                <img
                  className="w-100 shadow-lg d-dark-none rounded-2"
                  src={bg31}
                  alt="hero-header"
                />
                <img
                  className="w-100 d-light-none rounded-2"
                  style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                  src={anubis}
                  alt="hero-header"
                />
              </div>
            </div>
            <div className="hero-image-container position-absolute top-0 bottom-0 end-0 d-none d-lg-block">
              <div className="position-relative h-100 w-100">
                <div className="position-absolute h-100 top-0 d-flex align-items-center end-0 hero-image-container-bg">
                  <img
                    className="pt-7 pt-md-0 w-100"
                    src={bg12}
                    alt="hero-header"
                  />
                </div>
                <div className="position-absolute h-100 top-0 d-flex align-items-center end-0">
                  <img
                    className="pt-7 pt-md-0 w-100 shadow-lg d-dark-none rounded-2"
                    src={bg28}
                    alt="hero-header"
                  />
                  <img
                    className="pt-7 pt-md-0 w-100 d-light-none rounded-2"
                    style={{
                      opacity: 1,
                      maxWidth: '70%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                      position: 'relative',
                      top: '-80px',
                      zIndex: 5
                    }}
                    src={anubis}
                    alt="hero-header"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="text-lg-start text-center pt-8 pb-6 order-0 position-relative"
          >
            <div>
              <h1 className="fs-3 fs-lg-2 fs-md-1 fs-lg-2 fs-xl-1 fw-black mb-4">
                Intelligent Networks<br />
                <span className="text-success me-3">Powered by</span><br />
                <span className="text-success-light me-3">ANUBIS AI </span>
              </h1>
              <p className="mb-5">
                Embrace the next generation of AI-driven infrastructure for a smarter, more connected enterprise.
              </p>
              <a href="#contact">
                <Button variant='subtle-success'> Request a Demo </Button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HeroHeader;