import { Col, Row } from 'react-bootstrap';
import HelpCenter from './HelpCenter';
import QueryForm from './QueryForm';
import Mapbox from 'components/base/MapBox';

const AddressSection = () => {
  return (
    <section id="contact">
      <div className="container-small px-lg- px-xxl-3">
      <Row className="g-5 g-lg-5">
          <Col
            xs={12}
            md={6}
            className="mb-5 mb-md-0 text-center text-md-start"
          >
            <HelpCenter />
          </Col>
          <Col xs={12} md={6} className="text-center text-md-start">
            <QueryForm />
          </Col>
        </Row>
      </div>
    </section>

  );
};

export default AddressSection;
