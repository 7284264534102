import React from 'react';
import logoDark from 'assets/img/Creatimus/LogoDark.png';
import classNames from 'classnames';

interface LogoProps {
  width?: number;
  text?: boolean;
  textClass?: string;
  className?: string; 
}

const LogoDark = ({ width = 200, text = true, textClass, className }: LogoProps) => {
  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <img src={logoDark} alt="Creatimus" width={width} />
    </div>
  );
};

export default LogoDark;
